import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import SystemProfilePage from 'src/pages/dashboard/system/profile';
import SystemCardsPage from 'src/pages/dashboard/system/cards';
import SystemListPage from 'src/pages/dashboard/system/list';
import SystemCreatePage from 'src/pages/dashboard/system/new';
import SystemEditPage from 'src/pages/dashboard/system/edit';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
const OverviewRegionPage = lazy(() => import('src/pages/dashboard/region'));


//User QANATOMY
const UserProfilePage = lazy(() => import('src/pages/dashboard/users/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/users/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/users/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/users/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/users/edit'));



//Region QANATOMY

const RegionProfilePage = lazy(() => import('src/pages/dashboard/regions/profile'));
const RegionCardsPage = lazy(() => import('src/pages/dashboard/regions/cards'));
const RegionListPage = lazy(() => import('src/pages/dashboard/regions/list'));
const RegionAccountPage = lazy(() => import('src/pages/dashboard/regions/account'));
const RegionCreatePage = lazy(() => import('src/pages/dashboard/regions/new'));
const RegionEditPage = lazy(() => import('src/pages/dashboard/regions/edit'));


//Question QANATOMY

const QuestionProfilePage = lazy(() => import('src/pages/dashboard/questions/profile'));
const QuestionCardsPage = lazy(() => import('src/pages/dashboard/questions/cards'));
const QuestionListPage = lazy(() => import('src/pages/dashboard/questions/list'));
const QuestionAccountPage = lazy(() => import('src/pages/dashboard/questions/account'));
const QuestionCreatePage = lazy(() => import('src/pages/dashboard/questions/new'));
const QuestionEditPage = lazy(() => import('src/pages/dashboard/questions/edit'));
const QuestionUploadPage = lazy(() => import('src/pages/dashboard/questions/upload_questions'));


// Customer
const CustomerProfilePage = lazy(() => import('src/pages/dashboard/customer/profile'));
const CustomerCardsPage = lazy(() => import('src/pages/dashboard/customer/cards'));
const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list'));
const CustomerAccountPage = lazy(() => import('src/pages/dashboard/customer/account'));
const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customer/new'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));


// Transfer
const TransferProfilePage = lazy(() => import('src/pages/dashboard/transfer/profile'));
const TransferCardsPage = lazy(() => import('src/pages/dashboard/transfer/cards'));
const TransferListPage = lazy(() => import('src/pages/dashboard/transfer/list'));
const TransferAccountPage = lazy(() => import('src/pages/dashboard/transfer/account'));
const TransferCreatePage = lazy(() => import('src/pages/dashboard/transfer/new'));
const TransferEditPage = lazy(() => import('src/pages/dashboard/transfer/edit'));



// Subscription
const SubscriptionProfilePage = lazy(() => import('src/pages/dashboard/subscription/profile'));
const SubscriptionCardsPage = lazy(() => import('src/pages/dashboard/subscription/cards'));
const SubscriptionListPage = lazy(() => import('src/pages/dashboard/subscription/list'));
const SubscriptionAccountPage = lazy(() => import('src/pages/dashboard/subscription/account'));
const SubscriptionCreatePage = lazy(() => import('src/pages/dashboard/subscription/new'));
const SubscriptionEditPage = lazy(() => import('src/pages/dashboard/subscription/edit'));



// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const FaqsPage = lazy(() => import('src/pages/dashboard/faqs'));
const PrivacyPage = lazy(() => import('src/pages/dashboard/privacy'));
const TermsPage = lazy(() => import('src/pages/dashboard/terms'));
const AboutUsPage = lazy(() => import('src/pages/dashboard/aboutUs'));
const ContactUsPage = lazy(() => import('src/pages/contact-us'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'Faqs', element: <FaqsPage /> },
      { path: 'terms', element: <TermsPage /> },
      { path: 'privacy', element: <PrivacyPage /> },
      { path: 'aboutUs', element: <AboutUsPage /> },
      { path: 'contactUs', element: <ContactUsPage /> },
      { path: 'region', element: <OverviewRegionPage /> },

      // QANATOMY
      {
        path: 'region',
        children: [
          { element: <RegionProfilePage />, index: true },
          { path: 'profile/:id', element: <RegionProfilePage /> },
          { path: 'cards', element: <RegionCardsPage /> },
          { path: 'list', element: <RegionListPage /> },
          { path: 'new', element: <RegionCreatePage /> },
          { path: ':id/edit', element: <RegionEditPage /> },
          { path: 'account', element: <RegionAccountPage /> },
        ],
      },
      // Question QAnatomy
      {
        path: 'question',
        children: [
          { element: <QuestionProfilePage />, index: true },
          { path: 'profile/:id', element: <QuestionProfilePage /> },
          { path: 'cards', element: <QuestionCardsPage /> },
          { path: 'list', element: <QuestionListPage /> },
          { path: 'new', element: <QuestionCreatePage /> },
          { path: ':id/edit', element: <QuestionEditPage /> },
          { path: 'account', element: <QuestionAccountPage /> },
          {path: 'create', element: <QuestionUploadPage />},
        ],
      },
      {
        path: 'system',
        children: [
          { element: <SystemProfilePage />, index: true },
          { path: 'profile', element: <SystemProfilePage /> },
          { path: 'cards', element: <SystemCardsPage /> },
          { path: 'list', element: <SystemListPage /> },
          { path: 'new', element: <SystemCreatePage /> },
          { path: ':id/edit', element: <SystemEditPage /> },
        ],
      }, 
      {
        path: 'customer',
        children: [
          { element: <CustomerProfilePage />, index: true },
          { path: 'profile', element: <CustomerProfilePage /> },
          { path: 'cards', element: <CustomerCardsPage /> },
          { path: 'list', element: <CustomerListPage /> },
          { path: 'new', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
          { path: 'account', element: <CustomerAccountPage /> },
        ],
      },
      {
        path: 'transfer',
        children: [
          { element: <TransferProfilePage />, index: true },
          { path: 'profile', element: <TransferProfilePage /> },
          { path: 'cards', element: <TransferCardsPage /> },
          { path: 'list', element: <TransferListPage /> },
          { path: 'new', element: <TransferCreatePage /> },
          { path: ':id/edit', element: <TransferEditPage /> },
          { path: 'account', element: <TransferAccountPage /> },
        ],
      },
      {
        path: 'subscription',
        children: [
          { element: <SubscriptionProfilePage />, index: true },
          { path: 'profile/:id', element: <SubscriptionProfilePage /> },
          { path: 'cards', element: <SubscriptionCardsPage /> },
          { path: 'list', element: <SubscriptionListPage /> },
          { path: 'new', element: <SubscriptionCreatePage /> },
          { path: ':id/edit', element: <SubscriptionEditPage /> },
          { path: 'account', element: <SubscriptionAccountPage /> },
        ],
      },
      {
        path: 'users',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile/:id', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },

      { path: 'chat', element: <ChatPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
